import {apolloClient} from '@/api/client';
import {authenticateBroadcastChannel} from '@/queries/broadcasting.graphql';

const socketAuths = {};

export function setAuthResponseForChannel(channel, socketAuth) {
    if (!socketAuth) {
        return;
    }

    socketAuths[channel] = socketAuth;
}

export const GraphQLAuthorizer = (channel, options) => {
    return {
        authorize: (socketId, callback) => {
            if (socketAuths[channel.name]) {
                callback(null, JSON.parse(socketAuths[channel.name]));

                delete socketAuths[channel.name];

                return;
            }

            apolloClient
                .query({
                    query: authenticateBroadcastChannel,
                    variables: {
                        channel: channel.name,
                        socketId: socketId,
                    },
                })
                .then(({loading, data}) => {
                    if (loading) {
                        return;
                    }

                    if (data.result === null) {
                        throw new Error(`Could not authenticate socket to broadcasting channel: ${channel.name}`);
                    }

                    callback(null, {
                        auth: data.result.token,
                        channel_data: data.result.data,
                    });
                })
                .catch(err => {
                    callback(err, {auth: ''});
                });
        },
    };
};
