export function isDebug() {
    return window.DEBUG === true;
}

export function log() {
    if (!isDebug()) {
        return;
    }

    console.log(...arguments);

    return null;
}

export function logWarn() {
    if (!isDebug()) {
        return;
    }

    console.warn(...arguments);

    return null;
}

export function logError() {
    if (!isDebug()) {
        return;
    }

    console.error(...arguments);

    return null;
}
