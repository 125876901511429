import Pusher from 'pusher-js';

import {GraphQLAuthorizer} from './pusher-auth';

export const REALTIME_ENABLED = window.REALTIME !== undefined && window.REALTIME.ENABLED === true;

let client = null;

export function pusherResolver() {
    if (!REALTIME_ENABLED) {
        return;
    }

    if (client === null) {
        client = new Pusher(window.REALTIME.APPID, {
            wsHost: window.REALTIME.HOST || undefined,
            wsPort: window.REALTIME.WSPORT || undefined,
            wssPort: window.REALTIME.WSPORT || undefined,
            cluster: 'eu',
            forceTLS: true,
            authorizer: GraphQLAuthorizer,
            enableStats: false,
            enabledTransports: ['ws', 'wss'],
        });
    }

    return client;
}
