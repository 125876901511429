import {HttpLink} from 'apollo-link-http';
import {ApolloLink} from 'apollo-link';

const endpoint = window.location.origin + (window.USER !== undefined ? '/api/graphql/web' : '/api/graphql');

const headers = {
    'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content,
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
};

if (window.TEAM && window.TEAM.slug) {
    headers['X-Chief-Team'] = window.TEAM.slug;
}

const httpLink = new HttpLink({
    uri: endpoint,
    headers: headers,
    credentials: 'same-origin',
});

const profileRequests = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        if (window.phpdebugbar !== undefined) {
            const {
                response: {headers},
            } = operation.getContext();

            const datasetId = headers.get('phpdebugbar-id');

            if (datasetId !== null) {
                phpdebugbar.loadDataSet(datasetId, '(ajax)', undefined, true);
            }
        }

        return response;
    });
});

export default profileRequests.concat(httpLink);
