import VueApollo from 'vue-apollo';
import {ApolloLink} from 'apollo-link';
import {ApolloClient} from 'apollo-client';
import {queryResolvers} from '@/queries/resolvers';
import {withClientState} from 'apollo-link-state';
import {logWarn, logError, isDebug} from '@/lib/log';
import introspectionQueryResultData from './possibleTypes';
import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';

import httpLink from './http-link';
import pusherLink from './realtime/pusher-link';

const fragmentMatcher = new IntrospectionFragmentMatcher({introspectionQueryResultData});

const cache = new InMemoryCache({fragmentMatcher});

const link = ApolloLink.from([
    withClientState({
        cache,
        resolvers: queryResolvers,
    }),
    pusherLink,
    httpLink,
]);

export const apolloClient = new ApolloClient({
    link,
    cache,
    resolvers: queryResolvers,
    connectToDevTools: true,
});

function reloadBecauseUnauthenticated() {
    logWarn('Reloading because of unauthenticated state.');

    window.UNAUTHENTICATED_RELOAD_PENDING = true;

    return window.location.reload();
}

export default new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
        $query: {
            fetchPolicy: 'cache-and-network',
        },
    },

    watchLoading: (isLoading, countModifier) => {
        isLoading ? progress_start() : progress_end();
    },
    errorHandler: ({networkError, graphQLErrors}) => {
        // Reload the page when the API request is unauthenticated or expired this
        // forces the login page or the generation of a new API key / CSRF token
        if (networkError && (networkError.statusCode === 401 || networkError.statusCode === 419) && !isDebug()) {
            return reloadBecauseUnauthenticated();
        }

        if (graphQLErrors) {
            graphQLErrors.map(err => {
                if (err.message === 'Unauthenticated.') {
                    return reloadBecauseUnauthenticated();
                }

                logError(`[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`, err);
            });
        }

        if (networkError) {
            logError(`[Network error]: ${networkError}`, networkError);
        }
    },
});
